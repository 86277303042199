import React, { useEffect } from "react";
import TWEEN from "@tweenjs/tween.js";

interface Options {
  duration: number;
  initial: number;
}

const defaultOptions: Options = {
  duration: 300,
  initial: 0,
};

export const useTween = (value: number, opts: Partial<Options>) => {
  const options: Options = { ...defaultOptions, ...opts };
  const [previous, setPrevious] = React.useState<number>(options.initial);
  const [animatedValue, setAnimatedValue] = React.useState<number>(value);

  useEffect(() => {
    const tween = new TWEEN.Tween<{ value: number }>({ value: previous }, false)
      .to({ value }, options.duration)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate((x) => {
        setAnimatedValue(x.value);
      })
      .onComplete(() => {
        setAnimatedValue(value);
      })
      .start(); // Start the tween immediately.

    // Setup the animation loop.
    function animate(time: number) {
      tween.update(time);
      if (tween.isPlaying()) {
        requestAnimationFrame(animate);
      }
    }
    requestAnimationFrame(animate);

    setPrevious(value);
    return () => {
      tween.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return animatedValue;
};
