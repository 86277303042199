import { Button } from "react-bootstrap";
import styled from "@emotion/styled";

const colorForVariant = ({ variant }: { variant?: string }) => {
  switch (variant) {
    case "danger":
      return "#df2b00";
    case "secondary":
      return "#9ab0b6";
    default:
      return "#df8500";
  }
};

export const GPButton = styled(Button)`
  background-color: ${colorForVariant};
  border-color: ${colorForVariant};
  &:active,
  &:focus,
  &:hover {
    background-color: ${colorForVariant};
    border-color: ${colorForVariant};
  }

  &:active {
    transform: translate(0px, 2px);
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem ${colorForVariant}55;
  }
`;
