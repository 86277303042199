import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { getBoardAssessmentsCost, getConstant } from "../../selectors";
import { useTween } from "../../util/useTween";
import ClockIcon from "./ClockIcon";
import { RootState } from "../../root-store";

const GuageContainer = styled.div({
  label: "GuageContainer",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginLeft: 25,
  marginRight: 25,
});

export const CostGuage: React.FC = () => {
  const cost = useSelector(getBoardAssessmentsCost);

  const budget = useSelector((state: RootState) => {
    return getConstant(state, "Budget", "all", 0)?.value || 0;
  });
  const filled = Math.min(budget, cost);
  const empty = budget - filled;
  const animatedFilled = Math.floor(useTween(filled, {}));
  const animatedEmpty = Math.ceil(useTween(empty, {}));
  return (
    <GuageContainer>
      {Array.from({ length: animatedFilled }, (_, i) => (
        <ClockIcon color="#2D3C4D" key={i} />
      ))}
      {Array.from({ length: animatedEmpty }, (_, i) => (
        <ClockIcon color="#a5b2c7" key={i} />
      ))}
    </GuageContainer>
  );
};
