import React, { ReactNode } from "react";
import { BuffTypes } from "../treatments";

// const BadgeTypes: Record<BuffTypes | "Cost" | "Tag", string> = {
//   Trust: "primary",
//   Insight: "info",
//   Cost: "warning",
//   Tag: "secondary",
//   Uptake: "success",
// };

const colors: Record<BuffTypes | "Cost" | "Tag", string> = {
  Trust: "#2bab83",
  Insight: "#2bab83",
  Cost: "#ffc107",
  Tag: "#6c757d",
  Uptake: "#2bab83",
};

export const BuffBadge = React.forwardRef<
  HTMLSpanElement,
  {
    buff: BuffTypes | "Cost" | "Tag";
    children: ReactNode;
  }
>(({ buff, children }, ref) => (
  <span className="badge" ref={ref} style={{ backgroundColor: colors[buff] }}>
    {children}
  </span>
));
