import useSWR from "swr";
import { airtableBase } from "./airtable";
import { FiveMPlusTraining, FiveMPlusTrainingCodes } from "./five-ms";
import { BuffTypes } from "./treatments";
import { ReactNode } from "react";
import { Badge } from "react-bootstrap";
import { BuffBadge } from "./game/BuffBadge";

export interface Gate {
  id: string;
  tier: number;
  category: FiveMPlusTraining;
  buff: BuffTypes;
  level: number;
}

const mapGate = (record: any): Gate => {
  const result: Gate = {
    id: record.id,
    tier: record.get("Tier"),
    category: FiveMPlusTrainingCodes[record.get("5Ms")],
    buff: record.get("Buff"),
    level: record.get("Required Level"),
  };

  return result;
};

export function loadGates(): Promise<Gate[]> {
  return new Promise<Gate[]>((resolve, reject) => {
    airtableBase("Gates")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err || !records) {
          console.error(err);
          window.alert(
            "Error fetching data from Airtable! See console for details."
          );
          reject();
          return;
        }

        // console.log(
        //   `fetched ${records.length} Treatment/Assessment rows`,
        //   records
        // );

        resolve(records.map(mapGate));
      });
  });
}

export const fullfillsGates = (
  current: Record<BuffTypes, number>,
  gates: Gate[],
  tier: number
): ReactNode | null => {
  const insightRequired = requiredLevel("Insight", tier, gates);
  const trustRequired = requiredLevel("Trust", tier, gates);
  const insightOk = (current["Insight"] ?? 0) >= insightRequired;
  const trustOk = (current["Trust"] ?? 0) >= trustRequired;

  if (!insightOk && !trustOk) {
    return (
      <span>
        Raise <BuffBadge buff="Insight">Insight</BuffBadge> to {insightRequired}{" "}
        and <BuffBadge buff="Trust">Trust</BuffBadge>
        to {trustRequired} to access this tier.
      </span>
    );
  }

  if (!insightOk) {
    return (
      <span>
        Raise <BuffBadge buff="Insight">Insight</BuffBadge> to {insightRequired}{" "}
        to access this tier.
      </span>
    );
  }

  if (!trustOk) {
    return (
      <span>
        Raise <BuffBadge buff="Trust">Trust</BuffBadge> to {trustRequired} to
        access this tier.
      </span>
    );
  }

  return null;
};

const requiredLevel = (
  buff: BuffTypes,
  tier: number,
  gates: Gate[]
): number => {
  const gate = gates.find((g) => g.buff === buff && g.tier === tier);

  if (!gate) {
    return 0;
  }

  return gate.level;
};

export const useGates = () => useSWR("gates", loadGates);
