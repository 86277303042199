import React, { useRef } from "react";
import { Treatment } from "../../treatments";
import styled from "@emotion/styled";
import { useHover } from "usehooks-ts";
import ClockIcon from "./ClockIcon";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BuffBadge } from "../BuffBadge";

import { useSelector } from "react-redux";
import { getTags } from "../../selectors";

const Hover = styled.div`
  label: Hover;
  position: absolute;
  top: 60px;
  left: 0;
  font-size: 0.8em;
  width: 140px;
  background-color: #60bae3;
  border-radius: 5px;
  padding: 5px;
`;

const AssessmentBoxContainer = styled.div<{
  enabled: boolean;
  selected: boolean;
  locked: boolean;
}>`
  label: AssessmentBoxContainer;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  line-height: 1.05em;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  height: 80px;
  padding: 4px;
  margin: 10px 0px;
  cursor: pointer;
  font-weight: bold;
  border-width: 4px;
  border-style: ${(props) => (props.locked ? "dashed" : "solid")};
  color: ${(props) =>
    props.selected ? "white" : props.enabled ? "white" : "#2c4e86"};
  border-color: ${(props) => (props.selected ? "white" : "#2c4e86")};
  background-color: ${(props) =>
    props.enabled ? "#2c4e86" : props.locked ? "" : "#a6cbf0"};
`;

const Label = styled.div`
  label: Label;
  font-size: 1em;
  text-align: center;
`;

export const Assessment: React.FC<{
  assessment: Treatment;
  selected: boolean;
  enabled: boolean;
  locked: boolean;
  readonly?: boolean;
  hover?: boolean;
  toggleSelected?: () => void;
  toggleEnabled?: () => void;
}> = ({
  assessment,
  selected,
  toggleSelected,
  toggleEnabled,
  enabled,
  hover,
  locked,
  readonly,
}) => {
  const ref = useRef(null);
  const tags = useSelector(getTags);
  const hovering = useHover(ref);

  return (
    <AssessmentBoxContainer
      enabled={enabled}
      ref={ref}
      locked={locked}
      selected={selected}
      onClick={toggleSelected}
    >
      <Label>{assessment.name}</Label>

      {hovering && hover && (
        <Hover>
          Cost:
          <br />
          {
            // One <ClockIcon /> for each assessment.cost
            [...Array(assessment.cost)].map((_, i) => (
              <ClockIcon style={{ width: 14 }} />
            ))
          }
          <br />
          Target Patients:
          <br />
          {(assessment.tags || []).map((tag) => (
            <OverlayTrigger
              key={tag}
              placement="top"
              overlay={<Tooltip id="tooltip">{tags[tag]}</Tooltip>}
            >
              <BuffBadge buff="Tag">{tag}</BuffBadge>
            </OverlayTrigger>
          ))}
          <br />
          Benefits:
          <br />
          {assessment.buffs.Trust && <BuffBadge buff="Trust">Trust</BuffBadge>}
          {assessment.buffs.Insight && (
            <BuffBadge buff="Insight">Insight</BuffBadge>
          )}
          {assessment.buffs.Uptake && (
            <BuffBadge buff="Uptake">Uptake</BuffBadge>
          )}
        </Hover>
      )}
    </AssessmentBoxContainer>
  );
};
