import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  areConstantsLoaded,
  getBoardAssessmentIds,
  getBoardBuffs,
  getConstant,
  getTreatments,
} from "../../selectors";
import styled from "@emotion/styled";

import {
  FiveM,
  FiveMIcons,
  FiveMPlusTraining,
  FiveMPlusTrainingNames,
} from "../../five-ms";
import { lightBlueBG } from "../../style";
import { AddAssessmentDialog } from "./AddAssessmentDialog";
import { Assessment } from "./Assessment";
import { Gauge } from "./Gauge";
import { CostGuage } from "./CostGuage";
import { RootState, useAppDispatch } from "../../root-store";
import officeBackground from "../../assets/doctor-office-blur.png";
import { GPButton } from "../../Button";
import { useNavigate } from "react-router-dom";
import {
  constantsLoaded,
  copyBoardTreatmentsToSimulation,
  setBoardAssessments,
  simTagsLoaded,
  tagsLoaded,
  treatmentsLoaded,
} from "../../simulation/simulation-slice";
import { advanceSimulation } from "../../simulation/simulate-worker-thunk";
import { constSelector } from "recoil";
import { loadTreatments } from "../../treatments";
import { loadConstants, loadSimTags } from "../../constants";
import { loadTagDescriptions, loadTagLabels } from "../../tags";

const DebugPanel = styled.div({
  label: "DebugPanel",
  position: "fixed",
  bottom: 10,
  left: 400,
  gap: 10,
  display: "flex",
  flexDirection: "row",
});
const ContinueButton = styled(GPButton)({
  label: "ContinueButton",
  position: "fixed",
  bottom: 10,
  right: 10,
});
const Background = styled.div((props: { background?: string }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundImage: props.background ? `url(${props.background})` : undefined,
}));

const Column = styled.div({
  label: "Column",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: lightBlueBG,
  borderRadius: 10,
  height: "100%",
  minWidth: 200,
  flex: "1 1 0px",
  width: 0,
  boxShadow: "0 0 3px #2c4e86",

  padding: 10,
});

const Columns = styled.div({
  label: "Columns",
  gap: 20,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  minHeight: 400,
  height: "70vh",
  padding: 45,
  maxWidth: 1200,

  marginLeft: "auto",
  marginRight: "auto",
  "> h1": {
    color: "#fff",
    backgroundColor: "#7995d0",
    borderBottom: "9px solid #f6d889",

    textAlign: "center",
    fontSize: 24,
    fontFamily: "Goldman",
    lineHeight: "150%",
  },
});

const StatsHeader = styled.h1({
  label: "StatsHeader",
  display: "block",
  padding: 5,
  color: "#FFF",
  textAlign: "center",
  fontSize: 24,
  fontFamily: "Goldman",
  lineHeight: "150%",
  borderBottom: "9px solid #f6d889",
  background: "#7995D0",
});

const Header = styled.h1({
  label: "Header",
  display: "block",
  padding: "2px 5px",
  fontSize: 18,
  borderRadius: 10,
  width: "100%",
  //backgroundColor: darkBlueBG,
  color: "#000",
  textAlign: "left",
});

const AssessmentList = styled.div({
  label: "AssessmentList",
  flexGrow: 1,
  flexShrink: 1,
  padding: 0,
  boxSizing: "border-box",
  overflowY: "auto",
  marginBottom: 10,
});

const AddButton = styled.button({
  label: "AddButton",
  backgroundColor: "#df8500",
  color: "#fff",
  display: "block",
  padding: 5,
  borderRadius: 5,
  width: "100%",
  border: "none",
  outline: "none",
  position: "relative",

  "&:active": {
    top: 1,
    left: 1,
  },
});

const AssessmentScreen = styled.div({
  label: "AssessmentScreen",
  display: "flex",
  flexDirection: "row",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});
const Stats = styled.div({
  label: "Stats",
  backgroundColor: "#c3cfe6",
  width: 275,
  height: "100%",
  flexGrow: 0,
  flexShrink: 0,
});

const categories: FiveMPlusTraining[] = Object.keys(
  FiveMPlusTrainingNames
) as FiveMPlusTraining[];



export const AssessmentsScreenLoader = () => {
  const loaded = useSelector(areConstantsLoaded);
  const dispatch = useDispatch();
  useEffect(() => {
    console.info("Loading constants...");
    loadTreatments()
      .then((treatments) => {
        console.info("Treatments loaded");
        dispatch(treatmentsLoaded(treatments));
      })
      .catch((e) => {
        console.error(e);
      });

    loadSimTags().then((simTags) => {
      console.info("Sim tags loaded");
      dispatch(simTagsLoaded(simTags));
    });

    loadConstants()
      .then((constants) => {
        console.info("Constants loaded");
        dispatch(constantsLoaded(constants));
      })
      .catch((e) => {
        console.error(e);
      });

    Promise.all([loadTagDescriptions(), loadTagLabels()]).then(
      ([tagDescriptions, tagLabelMap]) => {
        console.info("Tag descriptions loaded");
        dispatch(tagsLoaded({ tags: tagDescriptions, labels: tagLabelMap }));
      }
    );
  }, [dispatch]);

  if (!loaded) return `Loading constants...`;
  return <AssessmentsScreen />;
};


export const AssessmentsScreen = () => {
  //useSaveGame();
  //const ageBandIndex = useSelector(getCurrentAgeBand);
  const dispatch = useAppDispatch();
  const [addAssessmentType, setAddAssessmentType] =
    React.useState<FiveMPlusTraining | null>(null);
  const [addAssessmentInitialId, setAddAssessmentInitialId] = React.useState<
    string | null
  >(null);
  // const navigate = useNavigate();
  const assessments = useSelector(getTreatments);
  const enabledAssessments = useSelector(getBoardAssessmentIds);
  const totalBuffs = useSelector(getBoardBuffs);
  const navigate = useNavigate();

  const startingInsight = useSelector(
    (state: RootState) =>
      getConstant(state, "Starting Insight", "all", -1)?.value || 0
  );

  const startingTrust = useSelector(
    (state: RootState) =>
      getConstant(state, "Starting Trust", "all", -1)?.value || 0
  );

  const startingUptake = useSelector(
    (state: RootState) =>
      getConstant(state, "Starting Uptake", "all", -1)?.value || 0
  );

  const onDebugFill = useCallback(() => {
    // Auto fill in one of each M assessment.

    dispatch(
      setBoardAssessments({
        assessments: [
          assessments.find((t) => t.category === "matters")!.id,
          assessments.find((t) => t.category === "medication")!.id,
          assessments.find((t) => t.category === "mentation")!.id,
          assessments.find((t) => t.category === "mobility")!.id,
        ],
      })
    );
  }, [assessments, dispatch]);

  const onNextScreen = useCallback(() => {
    // Make sure we have at least one assessment from each 4m category.
    const categories = enabledAssessments
      .map((a) => assessments.find((t) => t.id === a)?.category)
      .filter((c) => c !== undefined && c !== "training") as FiveM[];
    const uniq = new Set(categories);
    if (uniq.size < 4) {
      alert("You must have at least one assessment from each 4M category.");
      return;
    }

    dispatch(copyBoardTreatmentsToSimulation({ ageBandIndex: 0 }));
    dispatch(advanceSimulation({}));

    navigate("/play/simresults");
  }, [assessments, dispatch, enabledAssessments, navigate]);

  const insightTotal = (totalBuffs.Insight || 0) + startingInsight;
  const trustTotal = (totalBuffs.Trust || 0) + startingTrust;
  const uptakeTotal = (totalBuffs.Uptake || 0) + startingUptake;
  const impactTotal = Math.min(
    100,
    Math.round((insightTotal + trustTotal + uptakeTotal) / 3)
  );

  // const nextScreen = useCallback(() => {
  //   navigate("/play/assessmentresults");
  // }, [navigate]);

  return (
    <>
      <Background background={officeBackground} />
      {addAssessmentType && (
        <AddAssessmentDialog
          totalBuffs={totalBuffs}
          assessmentType={addAssessmentType}
          initialId={addAssessmentInitialId}
          onCancel={() => setAddAssessmentType(null)}
        />
      )}

      <AssessmentScreen>
        <Stats>
          <StatsHeader>4Ms Protocol Plan</StatsHeader>
          <Gauge
            size={0.75}
            percent={insightTotal}
            label="Insight"
            color="teal"
          />
          <Gauge size={0.75} percent={trustTotal} label="Trust" color="teal" />
          <Gauge
            size={0.75}
            percent={uptakeTotal}
            label="Uptake"
            color="teal"
          />
          <CostGuage />

          <Gauge percent={impactTotal} label="Impact" color="orange" />
        </Stats>
        <Columns>
          {categories.map((name) => (
            <Column key={name}>
              <Header>
                {FiveMIcons[name] && (
                  <img
                    alt={name}
                    style={{ maxHeight: 20, marginRight: 20 }}
                    src={FiveMIcons[name]}
                  />
                )}
                {FiveMPlusTrainingNames[name]}
              </Header>
              <AssessmentList>
                {assessments
                  .filter(
                    (t) =>
                      t.category === name && enabledAssessments.includes(t.id)
                  )
                  .map((assessment) => (
                    <Assessment
                      hover={true}
                      key={assessment.id}
                      assessment={assessment}
                      enabled={true}
                      selected={false}
                      locked={false}
                      toggleSelected={() => {
                        setAddAssessmentInitialId(assessment.id);
                        setAddAssessmentType(name);
                      }}
                      toggleEnabled={() => true}
                      readonly={true}
                    />
                  ))}
              </AssessmentList>
              <AddButton onClick={() => setAddAssessmentType(name)}>
                Add Screening
              </AddButton>
            </Column>
          ))}
        </Columns>

        <DebugPanel>
          <GPButton variant="secondary" onClick={onDebugFill}>
            Autofill
          </GPButton>
        </DebugPanel>

        <ContinueButton onClick={onNextScreen}>Continue</ContinueButton>
      </AssessmentScreen>
    </>
  );
};
