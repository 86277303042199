import React, { ReactNode, useCallback, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import ReactMarkdown from "react-markdown";
import { Modal, Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import styled from "@emotion/styled";
import {
  FiveMIcons,
  FiveMPlusTraining,
  FiveMPlusTrainingNames,
} from "../../five-ms";
import { useDispatch, useSelector } from "react-redux";
import {
  getBoardAssessmentIds,
  getConstant,
  getTags,
  getTreatments,
} from "../../selectors";

import { BuffTypes, Treatment } from "../../treatments";
import { setBoardAssessments } from "../../simulation/simulation-slice";
import { Assessment } from "./Assessment";
import { fullfillsGates, useGates } from "../../gates";
import { BuffBadge } from "../BuffBadge";
import { RootState } from "../../root-store";
import ClockIcon from "./ClockIcon";
import { GPButton } from "../../Button";

const Centered = styled.div`
  label: Centered;
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  label: Description;
  width: 100%;

  padding: 10px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

const AssessmentList = styled.div`
  label: AssessmentList;
  display: flex;
  user-select: none;
  gap: 10px;
`;

const TagBox = styled.div`
  label: TagBox;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  background-color: #9ab0b6;
  color: #fff;
  padding: 5px 15px;
  margin: 3px;
  border-radius: 5px;
`;

const Container = styled.div`
  label: Container;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
`;

const TextContainer = styled.div`
  label: TextContainer;
  max-width: 700px;
  font-size: 0.9em;
  line-height: 1.1em;
  margin-right: auto;
`;

const Clocks = styled.div`
  label: Clocks;
  display: flex;
  gap: 2px;
  flex-grow: 1;
`;

const Details = styled.div`
  label: Detail;
  min-width: 300px;
  max-width: 800px;
  flex-grow: 1;
  box-sizing: border-box;
  border-left: 1px solid #fff;
`;

const Grid = styled.div`
  label: Grid;
  display: flex;
  margin-right: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  margin-left: 30px;

  box-sizing: border-box;
`;

const Empty = styled.div`
  label: Empty;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
`;

const AssessmentTitle = styled.div`
  label: AssessmentTitle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export const AddAssessmentDialog: React.FC<{
  assessmentType: FiveMPlusTraining;
  initialId: string | null;
  totalBuffs: Record<BuffTypes, number>;
  onCancel: () => void;
  readonly?: boolean;
}> = ({ assessmentType, onCancel, initialId, totalBuffs, readonly }) => {
  const enabledAssessments = useSelector(getBoardAssessmentIds);
  const [temporaryAssessments, setTemporaryAssessments] =
    useState<string[]>(enabledAssessments);
  const assessments = useSelector(getTreatments);
  const budget = useSelector((state: RootState) => {
    return getConstant(state, "Budget", "all", 0)?.value || 0;
  });
  const cost = useMemo(
    () =>
      temporaryAssessments.reduce(
        (acc, id) => acc + (assessments.find((a) => a.id === id)?.cost ?? 0),
        0
      ),
    [assessments, temporaryAssessments]
  );

  const overBudget = cost > budget;

  const { data, isLoading } = useGates();
  const tags = useSelector(getTags);

  const [selectedAssessment, setSelectedAssessment] =
    useState<Treatment | null>(
      assessments.find((a) => a.id === initialId) ?? null
    );

  const addTempAssessment = useCallback(
    (id: string) => () => {
      setTemporaryAssessments((prev) => [...prev, id]);
    },
    []
  );
  const removeTempAssessment = useCallback(
    (id: string) => () => {
      setTemporaryAssessments((prev) => prev.filter((a) => a !== id));
    },
    []
  );

  const toggleAssessment = useCallback(
    (id: string) => () => {
      if (temporaryAssessments.includes(id)) {
        removeTempAssessment(id)();
      } else {
        addTempAssessment(id)();
      }
    },
    [addTempAssessment, removeTempAssessment, temporaryAssessments]
  );

  const dispatch = useDispatch();

  const commitAssessments = useCallback(() => {
    dispatch(
      setBoardAssessments({
        assessments: temporaryAssessments,
      })
    );
  }, [dispatch, temporaryAssessments]);

  const onClose = useCallback(() => {
    commitAssessments();
    onCancel();
  }, [commitAssessments, onCancel]);

  //toggleBoardAssessment
  // const toggleAssessment = (id: string) => () => {
  //   dispatch(toggleBoardAssessment({ id }));
  // };
  const toggleSelected = (id: string) => () => {
    //setSelectedAssessment((prev) => (prev === id ? null : id));
    setSelectedAssessment(assessments.find((a) => a.id === id) ?? null);
  };

  const tiers = useMemo(
    () =>
      assessments
        .filter((t) => t.category === assessmentType)
        .reduce(
          (acc, t) => {
            acc[t.tier - 1].push(t);
            return acc;
          },
          [[], [], []] as Treatment[][]
        ),
    [assessmentType, assessments]
  );

  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  const gates = data.filter((g) => g.category === assessmentType);

  console.info("totalBuffs", totalBuffs);
  const lockedTiers: (ReactNode | null)[] = [
    fullfillsGates(totalBuffs, gates, 1),
    fullfillsGates(totalBuffs, gates, 2),
    fullfillsGates(totalBuffs, gates, 3),
  ];

  return (
    <Modal fullscreen={true} size="xl" show={true} onHide={onCancel}>
      <Modal.Header closeButton style={{ backgroundColor: "#c3cfe6" }}>
        {FiveMIcons[assessmentType] && (
          <img
            src={FiveMIcons[assessmentType]}
            alt={FiveMPlusTrainingNames[assessmentType]}
            style={{
              maxHeight: 30,
              position: "relative",
              top: -5,
              marginRight: 10,
            }}
          />
        )}
        <h2>{FiveMPlusTrainingNames[assessmentType]}</h2>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#c3cfe6" }}>
        <Container
          style={{
            maxWidth: "unset",
            minHeight: 580,

            overflowY: "auto",
          }}
        >
          <Grid>
            <div>
              {tiers
                .filter((t) => t.length > 0)
                .map((tier, index) => (
                  <div>
                    <h3>
                      Tier {index + 1}
                      {lockedTiers[index] && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">{lockedTiers[index]}</Tooltip>
                          }
                        >
                          <span>🔒</span>
                        </OverlayTrigger>
                      )}
                    </h3>
                    <AssessmentList>
                      {tier.map((assessment) => (
                        <Assessment
                          assessment={assessment}
                          enabled={temporaryAssessments.includes(assessment.id)}
                          selected={assessment.id === selectedAssessment?.id}
                          toggleSelected={toggleSelected(assessment.id)}
                          toggleEnabled={toggleAssessment(assessment.id)}
                          locked={!!lockedTiers[index]}
                        />
                      ))}
                    </AssessmentList>
                  </div>
                ))}
            </div>
          </Grid>
          <Details>
            {!!selectedAssessment || (
              <Empty>
                <Alert>
                  Select an assessment on the left to see its details.
                </Alert>
              </Empty>
            )}
            {selectedAssessment && (
              <Description>
                <AssessmentTitle>
                  {selectedAssessment.name}
                  {readonly || (
                    <GPButton
                      onClick={toggleAssessment(selectedAssessment.id)}
                      size="sm"
                      variant={
                        temporaryAssessments.includes(selectedAssessment.id)
                          ? "danger"
                          : "success"
                      }
                    >
                      {temporaryAssessments.includes(selectedAssessment.id)
                        ? "Remove"
                        : "Add"}
                    </GPButton>
                  )}
                </AssessmentTitle>

                <TagBox>
                  <span>Target Patients:</span>
                  {(selectedAssessment.tags || []).map((tag) => (
                    <OverlayTrigger
                      key={tag}
                      placement="top"
                      overlay={<Tooltip id="tooltip">{tags[tag]}</Tooltip>}
                    >
                      <BuffBadge buff="Tag">{tag}</BuffBadge>
                    </OverlayTrigger>
                  ))}
                </TagBox>
                <TagBox>
                  <span>Benefits:</span>
                  {selectedAssessment.buffs.Trust && (
                    <BuffBadge buff="Trust">Trust</BuffBadge>
                  )}
                  {selectedAssessment.buffs.Insight && (
                    <BuffBadge buff="Insight">Insight</BuffBadge>
                  )}
                  {selectedAssessment.buffs.Uptake && (
                    <BuffBadge buff="Uptake">Uptake</BuffBadge>
                  )}
                </TagBox>

                <TagBox>
                  Cost:
                  <Clocks>
                    {[...Array(selectedAssessment.cost)].map((_, i) => (
                      <ClockIcon height={24} />
                    ))}
                  </Clocks>
                </TagBox>
                <TextContainer>
                  <ReactMarkdown linkTarget="_blank">
                    {selectedAssessment.description}
                  </ReactMarkdown>
                </TextContainer>

                {selectedAssessment.interventions && (
                  <TextContainer>
                    <hr />
                    <h4>Interventions</h4>
                    <ReactMarkdown linkTarget="_blank">
                      {selectedAssessment.interventions}
                    </ReactMarkdown>
                  </TextContainer>
                )}

                {selectedAssessment.video && (
                  <Centered>
                    <ReactPlayer
                      width={500 * 0.75}
                      height={440 * 0.75}
                      controls={true}
                      url={`/videos/${selectedAssessment.video}`}
                    />
                  </Centered>
                )}
              </Description>
            )}
          </Details>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#c3cfe6" }}>
        {overBudget && (
          <div className="alert alert-danger">
            <ClockIcon style={{ marginRight: 10 }} />
            You are over budget, please remove an assessment before continuing
          </div>
        )}
        <GPButton disabled={overBudget} variant="success" onClick={onClose}>
          Save
        </GPButton>
      </Modal.Footer>
    </Modal>
  );
};
